<template>
    <header class="page-header section-padding-x pb-0 sub-bg-x bg-gray1-x">
        <div class="container mt-80">
            <div class="row">
                <div class="col-lg-5">
                    <div class="caption">
                        <h6>
                            مرحبا بك في الصفحة التفاعلية لـ
                        </h6>
                        <h1 class="fz-55">
                            طلب اسئجار السيارة
                        </h1>
                    </div>
                    <div class="text mt-0">
                        <p class="lh-base">
                            نحن نسعد بخدمتكم وتوفير أفضل الخدمات لتلبية احتياجاتكم للتنقل بكل راحة وسهولة. لتجربة عملية الحجز السلسة، يتم تقديم عملية الحجز في أربع خطوات بسيطة:
                        </p>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="swiper4" data-carousel="swiper" data-items="4" data-space="0" data-speed="1000">
                        <div class="row d-flex justify-content-center" data-swiper="container">
                            <div class="col-md-3 col-6">
                                <div class="swiper-slide">
                                    <div class="item text-center">
                                        <h2 class="fz-60 stroke num-font mt-30">01</h2>
                                        <h6 class="mb-0 pb-0">تحديد تواريخ</h6>
                                        <p class="fz-14 mt-0">
                                            الاستلام والتسليم
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="swiper-slide">
                                    <div class="item text-center">
                                        <h2 class="fz-60 stroke num-font mt-30">02</h2>
                                        <h6 class="mb-0 pb-0">اختيار الفرع</h6>
                                        <p class="fz-14 mt-0">
                                            المراد حجزه منها
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="swiper-slide">
                                    <div class="item text-center">
                                        <h2 class="fz-60 stroke num-font mt-30">03</h2>
                                        <h6 class="mb-0 pb-0">اختيار السيارة</h6>
                                        <p class="fz-14 mt-0">
                                            ضمن الخيارات المتاحة
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-6">
                                <div class="swiper-slide">
                                    <div class="item text-center">
                                        <h2 class="fz-60 stroke num-font mt-30">04</h2>
                                        <h6 class="mb-0 pb-0">بياناتك الشخصية</h6>
                                        <p class="fz-14 mt-0">
                                            الاساسية للحجز
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-marq xlrg section-padding pb-4" dir="ltr">
                <div class="slide-har st1">
                    <div class="box">
                        <div class="item">
                            <h4>الفاو</h4>
                        </div>
                        <div class="item">
                            <h4>مع</h4>
                        </div>
                        <div class="item">
                            <h4>سيارة</h4>
                        </div>
                        <div class="item">
                            <h4>استئجار</h4>
                        </div>
                    </div>
                    <div class="box">
                        <div class="item">
                            <h4>الفاو</h4>
                        </div>
                        <div class="item">
                            <h4>مع</h4>
                        </div>
                        <div class="item">
                            <h4>سيارة</h4>
                        </div>
                        <div class="item">
                            <h4>استئجار</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <!-- ==================== #1 ==================== -->
    <section 
        class="steps page-header section-padding pb-0 sub-bg position-re o-hidden radius-30"
        v-if="isFinish === 0"
    >
        <div class="container ontop">
            <div class="sec-lg-head mb-80">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="position-re">
                            <h6 class="dot-titl colorbg-3 mb-10">1/4</h6>
                            <span class="mx-2">
                                الخطوة الأولى
                            </span>
                            <h2 class="fz-60 fw-700 text-dark">
                                تحديد تواريخ الحجز
                            </h2>
                        </div>
                    </div>
                    <div class="col-lg-4 d-flex align-items-center">
                        <div class="text">


                        </div>
                    </div>
                </div>
                <hr />
            </div>

            <div class="d-none">
                <span id="dSelected1" ></span>
                <span id="dSelected2" ></span>
            </div>

            <div class="" dir="ltr">
                <div class="row">
                    <div class="col-md-12">
                        <div class="calendar-section">
                            <div class="row no-gutters">
                                <div class="col-md-6">
                                    <div class="calendar calendar-first" id="calendar_first">
                                        <div class="calendar_header">
                                            <button class="switch-month switch-left">
                                                <i class="fa fa-chevron-left"></i>
                                            </button>
                                            <h2></h2>
                                            <button class="switch-month switch-right">
                                                <i class="fa fa-chevron-right"></i>
                                            </button>
                                        </div>
                                        <div class="calendar_weekdays"></div>
                                        <div class="calendar_content"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="calendar calendar-second" id="calendar_second">
                                        <div class="calendar_header">
                                            <button class="switch-month switch-left">
                                                <i class="fa fa-chevron-left"></i>
                                            </button>
                                            <h2></h2>
                                            <button class="switch-month switch-right">
                                                <i class="fa fa-chevron-right"></i>
                                            </button>
                                        </div>
                                        <div class="calendar_weekdays"></div>
                                        <div class="calendar_content"></div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Row -->
                        </div>
                        <!-- End Calendar -->
                    </div>
                </div>
            </div>

        </div>

        <div class="col-lg-12 py-4 text-center">
            <h3>
                <span class="mx-2 " v-if="receivedDate">
                    لقد اخترت 
                    <b>
                        تاريخ بداية الحجز من يوم
                    </b>
                    <span  class="text-success">
                        {{ receivedDay }}
                        {{ receivedDate }}
                    </span>
                </span>
                <br />
                <span class="mx-2 " v-if="deliveryDate">
                    - 
                    <b>
                        تاريخ انتهاء الحجز يوم 
                    </b>
                    <span  class="text-success">
                        {{ deliveryDay }}
                        {{ deliveryDate }}
                    </span>
                </span>
                <span class="mx-2 " v-if="Datelength">
                    <b>
                        مدة الحجز 
                    </b>
                    <span  class="text-success">
                        {{ Datelength }}
                    </span>
                </span>
            </h3>
        </div>


    </section>
    <!-- ==================== #2 ==================== -->
    <section 
        class="steps serv-box section-padding"
        v-if="isFinish === 0"
    >
        <div class="container">
            <div class="sec-lg-head mb-40">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="position-re">
                            <h6 class="dot-titl colorbg-3 mb-10">2/4</h6>
                            <span class="mx-2">
                                الخطوة الثانية
                            </span>
                            <h2 class="fz-60 fw-700 text-dark">
                                تحديد الفرع
                            </h2>
                        </div>
                    </div>
                    <div class="col-lg-6 d-flex align-items-center">
                        
                    </div>
                </div>
                <hr />
            </div>
            <h3 class=" mb-40">
                فرع الاستلام 
                <small class="d-block text-muted fz-15 mt-1" >
                    حرك الأسهم لاستعراض الفروع الأخرى 
                </small>   
            </h3>
            <swiper 
                :slides-per-view="slidesPerView" 
                :space-between="spaceBetween" 
                :breakpoints="breakpoints"

                :modules="modules"
                navigation               
            >
            
                <!-- div class="swiper-button opacity-25">
                    <div class="swiper-button-prev" >
                        <span class="left"></span>
                    </div>
                    <div class="swiper-button-next" >
                        <span class="right"></span>
                    </div>
                </div -->

                <swiper-slide 
                    v-for="item in SQLBranches"
                    :key="item.id"
                >
                    <div 
                    class="serv-item position-relative lg-pad md-mb50 radius-5 p-4 wow fadeIn" 
                    :class="{ 'border border-warning border-5' : receivingBranch === item.id }"
                    data-wow-delay=".5s"
                    >
                        <a :href="item.location" class="fz-12 btn btn-link position-absolute  top-0 start-0" v-if="item.location">
                            الخريطة
                        </a>

                        <small class="opacity-7">
                            [ {{  item.city }} ]
                        </small>
                        <h6 class="my-0 py-0">
                            {{  item.name }}
                        </h6>
                        <p class="fz-14 ">
                            {{  item.address }}
                        </p>
                        <div class="text-center mt-2">
                            
                            <a
                             class="btn  py-1 rounded-pill px-4"
                             :class="{ 'btn-warning': receivingBranch === item.id, 'btn-secondary': receivingBranch !== item.id }"
                             @click="selectReceivingBranch(item)"
                            >
                                اختيار
                            </a>
                        </div>

                    </div>
                </swiper-slide>
            </swiper>   

            <!-- ******************** -->
            <div class="col-12">
                <h4 class="checkbox mt-10">
                    <input class="form-check-input" type="checkbox" id="CBDeliveryBranch" @change="ChangeBranch()"  v-model="CBDeliveryBranch" />
                    <label for="CBDeliveryBranch" class=" mx-2">
                        فرع التسليم مختلف عن فرع الاستلام 
                    </label>
                </h4>
            </div>
            <div class="" v-if="CBDeliveryBranch">
                <hr>
                <h3 class=" mb-40">
                    فرع التسليم
                        
                    <small class="d-block text-muted fz-15 mt-1" >
                        حرك الأسهم لاستعراض الفروع الأخرى 
                    </small> 
                </h3>

                <swiper
                    :slides-per-view="slidesPerView" 
                    :space-between="spaceBetween" 
                    :breakpoints="breakpoints"

                    :modules="modules"
                    navigation    
                >

                        
                        <div class="swiper-button opacity-25">
                            <div class="swiper-button-prev" >
                                <span class="left"></span>
                            </div>
                            <div class="swiper-button-next" >
                                <span class="right"></span>
                            </div>
                        </div>
                        
                        <swiper-slide 
                            v-for="item in SQLBranches"
                            :key="item.id"
                        >
                        <div 
                            class="serv-item position-relative lg-pad md-mb50 radius-5 p-4 wow fadeIn" 
                            :class="{ 'border border-warning border-5' : deliveryBranch === item.id }" 
                            data-wow-delay=".5s">
                            <a :href="item.location" class="fz-12 btn btn-link position-absolute top-0 start-0" v-if="item.location">
                                شاهد على الخريطة
                            </a>
                            <small class="opacity-7">
                                [ {{  item.city }} ]
                            </small>
                            <h6 class="my-0 py-0">
                                {{ item.name }}
                            </h6>
                            <p class="fz-14">
                                {{ item.address }}
                            </p>
                            <div class="text-center mt-2">
                                <a
                                     class="btn py-1 rounded-pill px-4" 
                                     :class="{ 'btn-warning': deliveryBranch === item.id, 'btn-secondary': deliveryBranch !== item.id }" 
                                     @click="selectDeliveryBranch(item)"
                                >
                                    اختيار هذا الفرع
                                </a>
                            </div>
                        </div>
                    </swiper-slide>

                </swiper>   


                

            </div>
            <!-- ******************** -->
            <div class="col-lg-12 my-4 py-4 text-center">
                <h3>
                    <span class="mx-2 " v-if="receivingBranch">
                        لقد اخترت 
                        فرع الاستلام 
                        <span class="text-success">
                            {{ receivingBranchName }}
                        </span>
                        في مدينة 
                        <span class="text-success">
                        {{ receivingBranchCity }}
                        </span>

                        
                        <br />
                        <span class="mx-2 " v-if="deliveryBranch">
                            فرع التسليم 
                            <span class="text-success">
                                {{ deliveryBranchName }}
                            </span>
                            في مدينة 
                            <span class="text-success">
                            {{ deliveryBranchCity }}
                            </span>
                        </span>
                        <span class="text-danger" v-else>
                            فرع التسليم لم يتم تحديده بعد
                        </span>


                    </span>

                </h3>
            </div>
            <!-- ******************** -->
        </div>
    </section>
    <!-- ==================== #3 ==================== -->
    <section  
        class="steps work-carsouel section-padding sub-bg"
        v-if="isFinish === 0"
     >
        <div class="container">
            <div class="sec-lg-head mb-80">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="position-re">
                            <h6 class="dot-titl colorbg-3 mb-10">3/4</h6>
                            <span class="mx-2">
                                الخطوة الثالثة
                            </span>
                            <h2 class="fz-60 fw-700 text-dark">
                                تحديد السيارة
                                <small class="d-block text-muted fz-15 mt-1" >
                                    حرك الأسهم لاستعراض السيارات الأخرى
                                </small> 
                            </h2>
                            <div v-if="SQLCars && SQLCars.length > 0">
                                <h4>
                                    وجدنا حسب اختيارك 
                                    {{  SQLCars.length   }}
                                    عنصر 
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div  class="container-fluid rest">
            <div v-if="receivedDate && deliveryDate && receivingBranch !== 0">
                <div v-if="SQLCars && SQLCars.length > 0">
                    <swiper


                        :slides-per-view="slidesPerView" 
                        :space-between="spaceBetween" 
                        :breakpoints="breakpoints"

                        :modules="modules"
                        navigation    


                        @swiper="onSwiper"
                        @slideChange="onSlideChange"
                    >
                        <swiper-slide 
                            v-for="item in SQLCars"
                            :key="item.id"
                        >
                            <div
                             class="swiper-slide "
                             :class="{ 'border border-warning border-5' : car.id === item.id }" 
                             >
                                <a 
                                    class="item" 
                                    @click="selectCar(item)"
                                >
                                    <div class="img">
                                        <img :src="item.poster" alt="" />
                                        <div class="cont">
                                            <span class="mb-5">
                                                <small>
                                                    {{ item.brand  }}
                                                </small>
                                                {{ item.type_name  }}
                                                / 
                                                {{ item.model  }}
                                            </span>
                                            <h6 class="fz-18">
                                                {{ item.price  }} SR
                                            </h6>
                                        </div>
                                    </div>
                                </a>
                            </div>                
                        </swiper-slide>
                    </swiper>
                </div>
                <div v-else>
                    <h3 class="my-4 py-4 text-center text-danger">
                        لم نجد لك اي سيارة حسب عملية البحث التي قمت بها             
                    </h3>
                </div>
            </div>
            <div v-else>
                <h3 class="my-4 py-4 text-center text-danger">
                    فضلا قم باختيار تواريخ الحجز والفرع حتى تظهر لك السيارات المتاحة                    
                </h3>
            </div>


            <!-- ******************** -->
            <div class="col-lg-12 my-4 py-4 text-center">
                <h3>
                    <span class="mx-2 " v-if="car.id">
                        لقد اخترت 
                        السيارة
                        <span class="text-success">
                            {{ car.name }}
                        </span>
                        بسعر
                        <span class="text-success en">
                        {{ car.price }} SR
                        </span>
                    </span>

                </h3>
            </div>
            <!-- ******************** -->


        </div>
    </section>
    <!-- ==================== #4 ==================== -->
    <section 
        class="steps contact-crev no-crev section-padding"
        v-if="isFinish === 0"
    >
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="sec-lg-head md-mb80">
                        <div class="position-re">
                            <h6 class="dot-titl colorbg-3 mb-10">4/4</h6>
                            <span class="mx-2">
                                الخطوة الرابعة
                            </span>
                            <h2 class="fz-60 fw-700 text-dark">
                                بيانات المستأجر
                            </h2>
                        </div>
                        <p class="fz- mt-10">
                            من خلال تقديم هذه المعلومات، فإنك تؤكد أنك تفهم وتوافق على الالتزام بجميع القوانين واللوائح المعمول بها في المملكة العربية السعودية.
                            <br />
                            يرجى التأكد من صلاحية رخصتك للقيادة وأنها سارية المفعول. سيتم استخدام هذه المعلومات فقط لأغراض تأجير السيارة ولن يتم مشاركتها مع أي طرف آخر     
                        </p>
                    </div>

                </div>
                <div class="col-lg-7 offset-lg-1-x">

                    <div class="full-width">
                        <div class="messages"></div>

                            <div class="controls row order-form">
                                <div class="col-lg-6">
                                    <div class="form-group mb-30">
                                        <label>الاسم بالكامل</label>
                                        <input type="text" placeholder="الاسم الأول والأب والأخير" v-model="customer.name" />
                                        <small v-if="!isNameValid && customer.name !== ''" class="error-message text-danger">
                                            يجب أن يكون على الأقل 5 أحرف
                                        </small>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group mb-30">
                                        <label>رقم الهوية</label>
                                        <input type="text" placeholder="10 أرقام" v-model="customer.idNumber" />
                                        <small v-if="!isIdNumberValid && customer.idNumber !== ''" class="error-message text-danger">
                                            يجب أن يكون 10 أرقام ، يبدأ بـ 1 أو 2
                                        </small>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group mb-30">
                                        <label>
                                            رقم الجوال
                                            <!-- small class="text-muted">(سيتم إرسال كود تحقق من صحة الرقم المدخل)</small -->
                                        </label>
                                        <div class="form-group d-flex">
                                            <input type="text" placeholder="05XXXXXXXX رقم سعودي" v-model="customer.mobileNumber" />
                                            <!-- button type="submit" class="butn butn-md butn-bord">
                                                <span>تحقق  </span>
                                            </button -->
                                        </div>
                                        <small v-if="!isMobileNumberValid && customer.mobileNumber !== ''" class="error-message text-danger">
                                            يجب أن يكون 10 أرقام، يبدأ بـ 0 أو 5
                                        </small>
                                    </div>
                                </div>

                                <div class="col-lg-8">
                                    <div class="form-group mb-30">
                                        <label class="mb-2">
                                            العمر مابين
                                        </label>
                                        <div class="row px-2">
                                            <div class="col-md-6 col-12 d-flex my-1">
                                                <input class="form-check-input-x w-25" type="radio" v-model="customer.age" id="r1" value="21_24">
                                                <label class="form-check-label w-100" for="r1">
                                                    21 حتى 24
                                                </label>
                                            </div>
                                            <div class="col-md-6 col-12 d-flex my-1">
                                                <input class="form-check-input-x w-25" type="radio" v-model="customer.age" id="r2" value="25_39">
                                                <label class="form-check-label w-100" for="r2">
                                                    25 حتى 39
                                                </label>
                                            </div>
                                            <div class="col-md-6 col-12 d-flex my-1">
                                                <input class="form-check-input-x w-25" type="radio" v-model="customer.age" id="r3" value="40_59">
                                                <label class="form-check-label w-100" for="r3">
                                                    40 حتى 59
                                                </label>
                                            </div>
                                            <div class="col-md-6 col-12 d-flex my-1">
                                                <input class="form-check-input-x w-25" type="radio" v-model="customer.age" id="r4" value="60">
                                                <label class="form-check-label w-100" for="r4">
                                                    60 ومافوق
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group mb-30">
                                        <label>
                                            ملاحظات
                                            <small class="text-muted">
                                                تود أن تخبرنا بها
                                            </small>
                                        </label>
                                        <textarea v-model="customer.note" rows="2" cols="1"  />

                                    </div>
                                </div>

                            </div>


                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- ==================== # ==================== -->
    <section class="section-padding sub-bg">
        <div class="container">
            <div class="sec-lg-head md-mb80">
                    <h2 class="fz-60 fw-700 text-dark">
                        مراجعة تفاصيل طلبك
                    </h2>
                    <hr />
            </div>

            <div class="row my-4 py-4">
                <div class="col-lg-4">
                    <div class="checkout-order-info">
                        <h4 class="mb-20">
                            تواريخ الحجز
                        </h4>
                        <div>
                            <ul class="rest">
                                <li class="">
                                    <div class="d-flex align-items-center">
                                        <div >
                                            <h6>
                                                تاريخ الاستلام
                                            </h6>
                                        </div>
                                        <div >
                                            <h5 class="main-color4 fz-20 mx-3 ">
                                                {{ receivedDay }}
                                                {{ receivedDate }}
                                            </h5>
                                        </div>
                                    </div>
                                </li>
                                
                                <li class="pt-1 bord-thin-top">
                                    <div class="d-flex align-items-center">
                                        <div >
                                            <h6>
                                                تاريخ التسليم
                                            </h6>
                                        </div>
                                        <div >
                                            <h5 class="main-color4 fz-20 mx-3 ">
                                                {{ deliveryDay }}
                                                {{ deliveryDate }}
                                            </h5>
                                        </div>
                                    </div>
                                </li>
                                
                                <li class="pt-1 bord-thin-top">
                                    <div class="d-flex align-items-center">
                                        <div >
                                            <h6>
                                                مدة الحجز (بالأيام)
                                            </h6>
                                        </div>
                                        <div >
                                            <h5 class="main-color4 fz-20 mx-3 " v-if="Datelength">
                                                {{ Datelength }}
                                                    يوم / أيام
                                            </h5>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="checkout-order-info">
                        <h4 class="mb-20">
                            الفرع 
                        </h4>
                        <div>
                            <ul class="rest">
                                <li class="pt-1 ">
                                    <div class="d-flex align-items-center">
                                        <div >
                                            <h6>
                                                فرع الاستلام
                                            </h6>
                                        </div>
                                        <div >
                                            <h5 class="main-color4 fz-20 mx-3 ">
                                                {{ receivingBranchName }}
                                            </h5>
                                        </div>
                                    </div>
                                </li>
                                <li class="pt-1 bord-thin-top">
                                    <div class="d-flex align-items-center">
                                        <div >
                                            <h6>
                                                فرع التسليم
                                            </h6>
                                        </div>
                                        <div >
                                            <h5 class="main-color4 fz-20 mx-3 ">
                                                {{ deliveryBranchName }}
                                            </h5>
                                        </div>
                                    </div>
                                </li>
                                <li class="pt-1 bord-thin-top">
                                    <div class="d-flex align-items-center">
                                        <div >
                                            <h6>
                                                السيارة المطلوبة
                                            </h6>
                                        </div>
                                        <div >
                                            <h5 class="main-color4 fz-20 mx-3 ">
                                                {{ car.name  }}
                                                (
                                                    {{ car.price  }}
                                                )
                                            </h5>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="checkout-order-info">
                        <h4 class="mb-20">
                            البيانات الشخصية
                        </h4>
                        <div>
                            <ul class="rest">
                                <li class="pt-1">
                                    <div class="d-flex align-items-center">
                                        <div >
                                            <h6>
                                                الاسم 
                                            </h6>
                                        </div>
                                        <div >
                                            <h5 class="main-color4 fz-20 mx-3 ">
                                                {{  customer.name }}
                                            </h5>
                                        </div>
                                    </div>
                                </li>
                                <li class="pt-1 bord-thin-top  bord-thin-bottom">
                                    <div class="d-flex align-items-center">
                                        <div >
                                            <h6>
                                                رقم الهوية
                                            </h6>
                                        </div>
                                        <div >
                                            <h5 class="main-color4 fz-20 mx-3 ">
                                                {{ customer.idNumber }}
                                            </h5>
                                        </div>
                                    </div>
                                </li>
                                <li class="pt-1 bord-thin-top  bord-thin-bottom">
                                    <div class="d-flex align-items-center">
                                        <div >
                                            <h6>
                                                رقم الجوال
                                            </h6>
                                        </div>
                                        <div >
                                            <h5 class="main-color4 fz-20 mx-3 ">
                                                {{ customer.mobileNumber }}
                                            </h5>
                                        </div>
                                    </div>
                                </li>
                                <li class="pt-1 bord-thin-top  bord-thin-bottom">
                                    <div class="d-flex align-items-center">
                                        <div >
                                            <h6>
                                                متوسط العمر
                                            </h6>
                                        </div>
                                        <div >
                                            <h5 class="main-color4 fz-20 mx-3">
                                                <span v-if="customer.age === '21_24'">21 - 24</span>
                                                <span v-else-if="customer.age === '25_39'">25 - 39</span>
                                                <span v-else-if="customer.age === '40_59'">40 - 59</span>
                                                <span v-else-if="customer.age === '60'">60 ومافوق</span>
                                                <span v-else>-</span>
                                            </h5>

                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="alert alert-danger mt-4" v-if="isError === 1">
                {{  MsgError  }}
            </div> 

            <div class="mt-30" v-if="isFormValid">
                <div v-if="isFinish === 0">
                    <button 
                        @click="FormSubmit" 
                        class="butn butn-md butn-bg main-colorbg4 text-dark"
                    >
                        <span class="text-u fw-600">
                            تأكيد , وإرسال نموذج الطلب
                        </span>
                    </button>
                </div>
                <div v-else-if="isFinish === 2">
                    جاري التحقق من الطلب 
                </div>
                <div v-else-if="isFinish === 1">
                    <!-- ******************** -->
                    <div class="col-lg-12 my-4 py-4 text-center">
                        <h3>
                            <span class="mx-2 " v-if="car.id">
                                تم الحجز المبدأي ، 
                                الرقم المرجعي لطلبك
                                <br />
                                <span class="text-success">
                                    {{ uid }}
                                </span>

                                <img :src="qrCodeUrl"  style="width: 200px;" />

                            </span>
                        </h3>
                    </div>
                    <!-- ******************** -->
                </div>  
            </div>
            <div v-else>
                <button class="butn butn-md btn-secondary text-white" disabled>
                    <span class="text-u fw-600">
                        تأكيد , وإرسال نموذج الطلب
                    </span>
                </button>
                <span class="text-danger mx-2">
                    فضلاً أكمل النموذج للإرسال
                </span>
            </div>


        </div>
    </section>
    <!-- ==================== # ==================== -->
</template>

<script>
import axios from 'axios';

import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';


export default {
    name: 'WizardPage',
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const onSwiper = () => {

        };
        const onSlideChange = () => {

        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation],
        };
    },
    data(){
        return {
            //hostLink : 'http://127.0.0.1:8000/',
            hostLink : 'https://www.admin.alfao.com.sa/',
            //--------------------
            isFinish : 0,
            uid : "",

            isError : 0,
            MsgError : "",
            //--------------------
            qrCodeUrl: '',
            //--------------------
            SQLCars: [],
            SQLBranches: [],
            //----------------- DATE  
            receivedDate: '',
            deliveryDate: '',
            receivedDay: '',
            deliveryDay: '',
            Datelength: 0,
            
            duration: '',
            //--------
            receivingBranch: 0,
            receivingBranchName: '',
            receivingBranchCity: '',
            //--------
            
            deliveryBranch: 0,
            deliveryBranchName: '',
            deliveryBranchCity: '',
            CBDeliveryBranch: false,
            //--------
            car: {
                name: '',
                price: ''
            },
            //--------
            customer: {
                name: '',
                idNumber: '',
                age: '',
                note: '',
                mobileNumber: '',
                validationCode: ''
            },
            //--------------------
            slidesPerView: 1,
            spaceBetween: 10,
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 40
                }
            }
            //--------------------

        }
    },
    computed: {
        isNameValid() {
            return this.customer.name.length >= 5;
        },
        isIdNumberValid() {
            const regex = /^[1-2]\d{9}$/;
            return regex.test(this.customer.idNumber);
        },
        isMobileNumberValid() {
            const regex = /^(0|5)\d{8,10}$/;
            return regex.test(this.customer.mobileNumber);
        },
        //--------------
        isFormValid() {
            // Check if any of the required fields are empty or equal to 0
            if (
                !this.receivedDate ||
                !this.deliveryDate ||
                !this.receivingBranch ||
                !this.deliveryBranch ||
                !this.car.name ||
                this.car.price === 0 ||
                !this.customer.name ||
                !this.customer.idNumber ||
                !this.customer.age ||
                !this.customer.mobileNumber
            ) {
                return false;
            }

            // Check validation for customer name, idNumber, and mobileNumber
            return this.isNameValid && this.isIdNumberValid && this.isMobileNumberValid;
        }

    },
    created(){
        this.getData();
    },
    mounted() {
        this.observeDateChanges();
        //this.generateQRCode();

    },
    methods : {
        //--------------------------
        getData() {
            axios.get(this.hostLink + 'api/ece4724c41ae49a3b7e98e1b97c59d1a/branches')
                .then((response) => {
                //console.log( response.data);
                this.SQLBranches = response.data;
                })
                //.catch((error) => {
                .catch(() => {
                    //console.error('Error fetching data:', error);
                });
        },
        //-----------------
        getCars() {
            this.car.id       = "";
            this.car.name     = "";
            this.car.price    = "";
            
            if (!this.receivingBranch || !this.receivedDate || !this.deliveryDate) {
                //console.log('Please enter all required fields.');
                return;
            }

            axios.post(this.hostLink + 'api/ece4724c41ae49a3b7e98e1b97c59d1a/get_cars', {
                branche: this.receivingBranch, 
                from: this.receivedDate,
                to: this.deliveryDate, 
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'LiceXXjppjAUATXTxQ9TmeOY4fD95fDW', 
                },
            })
            .then((response) => {
                //console.log(response.data);
                this.SQLCars = response.data;
                //*************
            })
            //.catch((error) => {
            .catch(() => {

                //console.error('Error fetching data:', error);
            });
        },
        //**********************/
        /*observeDateChanges() {
            const spanReceivedDate = document.getElementById('dSelected1');
            const spanDeliveryDate = document.getElementById('dSelected2');
            let receivedDateObj; // Declare receivedDateObj here

            // Observer for receivedDate
            const receivedDateObserver = new MutationObserver(mutationsList => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        this.receivedDate = spanReceivedDate.textContent;

                        // Fetch day name for receivedDate
                        receivedDateObj = new Date(this.receivedDate); // Assign receivedDateObj here
                        this.receivedDay = receivedDateObj.toLocaleDateString('ar', { weekday: 'long' });
                    }
                }
            });
            receivedDateObserver.observe(spanReceivedDate, { childList: true });

            // Observer for deliveryDate
            const deliveryDateObserver = new MutationObserver(mutationsList => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        this.deliveryDate = spanDeliveryDate.textContent;

                        // Fetch day name for deliveryDate
                        const deliveryDateObj = new Date(this.deliveryDate);
                        this.deliveryDay = deliveryDateObj.toLocaleDateString('ar', { weekday: 'long' });

                        // Calculate Datelength
                        const diffTime = Math.abs(deliveryDateObj - receivedDateObj);
                        this.Datelength = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    }
                }
            });
            deliveryDateObserver.observe(spanDeliveryDate, { childList: true });

        },*/

        observeDateChanges() {
            const spanReceivedDate = document.getElementById('dSelected1');
            const spanDeliveryDate = document.getElementById('dSelected2');
            let receivedDateObj; // Declare receivedDateObj here

            // Observer for receivedDate
            const receivedDateObserver = new MutationObserver(mutationsList => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        this.receivedDate = spanReceivedDate.textContent;

                        // Fetch day name for receivedDate
                        receivedDateObj = new Date(this.receivedDate); // Assign receivedDateObj here
                        this.receivedDay = receivedDateObj.toLocaleDateString('ar', { weekday: 'long' });
                    }
                }
            });
            receivedDateObserver.observe(spanReceivedDate, { childList: true });

            // Observer for deliveryDate
            const deliveryDateObserver = new MutationObserver(mutationsList => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        this.deliveryDate = spanDeliveryDate.textContent;

                        // Fetch day name for deliveryDate
                        const deliveryDateObj = new Date(this.deliveryDate);
                        this.deliveryDay = deliveryDateObj.toLocaleDateString('ar', { weekday: 'long' });

                        // Calculate Datelength
                        const diffTime = Math.abs(deliveryDateObj - receivedDateObj);
                        this.Datelength = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                        // Call getCars() after the calculation
                        this.getCars();
                    }
                }
            });
            deliveryDateObserver.observe(spanDeliveryDate, { childList: true });
        },
        //**********************/
        selectReceivingBranch(selectedBranch) {
        this.receivingBranch = selectedBranch.id;
        this.receivingBranchName  = selectedBranch.name;
        this.receivingBranchCity  = selectedBranch.city;

        if(this.CBDeliveryBranch === false){
            this.deliveryBranch     = this.receivingBranch;
            this.deliveryBranchName  = this.receivingBranchName;
            this.deliveryBranchCity  = this.receivingBranchCity;
        }

        this.getCars();

        },
        selectDeliveryBranch(selectedBranch) {
        this.deliveryBranch     = selectedBranch.id;
        this.deliveryBranchName  = selectedBranch.name;
        this.deliveryBranchCity  = selectedBranch.city;
        },
        ChangeBranch(){
        if(this.CBDeliveryBranch === true){
            this.deliveryBranch     = 0;
            this.deliveryBranchName  = "";
            this.deliveryBranchCity  = "";
        }else{
            this.deliveryBranch     = this.receivingBranch;
            this.deliveryBranchName  = this.receivingBranchName;
            this.deliveryBranchCity  = this.receivingBranchCity;
        }
        },
        //**********************/
        selectCar(selectCar) {
        this.car.id       = selectCar.id;
        this.car.name     = selectCar.brand + " " + selectCar.type_name + " " + selectCar.model;
        this.car.price    = selectCar.price;
        },

        
        //**********************/
        FormSubmit() {        
            if (this.isFormValid === false) {
                return;
            }

            this.isFinish = 3,
            this.isError = 0;
            this.MsgError = "";

            axios.post(this.hostLink + 'api/ece4724c41ae49a3b7e98e1b97c59d1a/request_order', {
                date_from       : this.receivedDate,
                date_to         : this.deliveryDate,
                branche_from    : this.receivingBranch,
                branche_to      : this.deliveryBranch,
                car             : this.car.id,
                customer_name   : this.customer.name,
                customer_age    : this.customer.age,
                customer_id     : this.customer.idNumber,
                customer_phone  : this.customer.mobileNumber,
                customer_note   : this.customer.note,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'LiceXXjppjAUATXTxQ9TmeOY4fD95fDW', 
                },
            })
            .then((response) => {
                //console.log(response.data);
                this.uid = response.data.success;
                
                this.qrCodeUrl =   `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${this.uid}&choe=UTF-8`;
                
                this.isFinish = 1;
                //*************
            })
            //.catch((error) => {
             .catch(() => {
                this.isFinish = 0,

                
                this.isError = 1;
                this.MsgError = "حدث خطأ أثناء إرسال النموذج ، فضلاً التحقق من المدخلات او التواصل مع الدعم الفني";
                //console.error('Error fetching data:', error);
            });
        }
        //**********************/
    },
    props: {

    },

}
</script>


